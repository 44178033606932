.contactme {
    margin: 1rem;
    padding: 1rem;
    background-color: #C9D1D3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    text-align: center;
    color: white;
}

.contactme h1 {
    width: 90%;
    margin-left:5%;
}

.contactme p {
    font-size: 1.5rem;
    width:90%;
    color: #000000;
    margin-left:7rem;
    line-height: 2.5rem;
}

.contactme ul {
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contactme ul li {
    margin: 2rem;
    font-size: 0.5em;
    color:white;
}

.flex {
    display: flex;
    align-items: center;
}

.contactme img {
    height:60px;
    margin: 3%;
    
}

.contactme ul div a:visited  {
    color: #204b9f;
    /* text-decoration: none; */
}

.title {
    word-spacing: 3px;
    letter-spacing: 2px;
    text-decoration: underline;
    padding-top: 3rem;
    /* padding-bottom: 2rem; */
    font-size: 3rem;
    color: #1B2A49;
    text-transform: uppercase;
    margin-bottom: 3rem;
}
.contactme a {
    text-decoration: none;
    font-size:1.1rem;
}
.contactme a:hover,
.contactme a:visited,
.contactme a.active {
    color: white;
}