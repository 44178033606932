.projectdetails {
    margin: 1rem;
    padding: 1rem;
    background-color: #C9D1D3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    text-align: center;
    color: white;
    /* display:flex; */

}

.projectdetails h1 {
    font-size: 2.5rem;
    width:75%;
    /* color: #f7e702; */
    margin-left:10%;
    line-height: 2.5rem;
    text-transform: uppercase;

}

.projectdetails h3 {
    color: #000000;
    margin: 0;
    padding: 2%;
    font-size: 1.5rem;
    line-height: 2.5rem;
}

/* .aboutme img {
    margin: 4%;
    border-radius: 50%;
    border: solid 2px  #86888a;
} */

.title {
    word-spacing: 3px;
    letter-spacing: 2px;
    text-decoration: underline;
    padding: 5rem 0 2rem;
    font-size: 5rem;

}