.aboutme {
    margin: 1rem;
    padding: 1rem;
    background-color: #C9D1D3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    text-align: center;
    color: white;
    /* text-decoration: underline; */
    display:flex;
    /* width:60%; */
    /* height:200%; */
}

.aboutme p {
    font-size: 1.5rem;
    width:75%;
    color: #000000;
    margin-left:5%;
    line-height: 2.5rem;
    /* text-decoration: none; */
}

.aboutme h3 {
    color: #000000;
    margin: 0;
    font-size: 1rem;
}

.aboutme img {
    margin: 4%;
    border-radius: 50%;
    border: solid 2px  #86888a;
}

.title {
    word-spacing: 3px;
    letter-spacing: 2px;
    text-decoration: underline;
    padding-top: 5rem;
    font-size: 3rem;
    color: #1B2A49;
    text-transform: uppercase;
    margin-bottom: 3rem;
}
