/* h1{ */
    /* color: white; */
    /* margin-right: 10%; */
    /* margin-top: 3%; */
/* } */
/* h3 {
    padding-left: 50px;
} */
/* div{
    width: 100%;
} */
.header {
    /* width: 100%; */
    height: 9rem;
    background-color: #1B2A49;
    padding: 0 6%;
    display: flex;
    color: white;
    padding-top:1%;
    /* width: 100%; */
}

/* .header nav {
    height: 100%;
} */

.header button {
    background-color:#1B2A49;
    color: white;
    border-style: white, 1px, solid;
}

.header ul {
    height: 100%;
    /*width: 100%; */
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    margin-left:35rem;
}

.header li {
    margin-right: 4rem;
    width: 6rem;
}

.header h3 {
    margin-left: 2rem;
    /* width: 6rem; */
}

.header a {
    color: white;
    text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
    color: #95bcf0;
    padding-bottom: 0.25rem;
    border-bottom: 4px solid #95bcf0;
}

/* .marginRight{
    padding-right:10%;
} */