.projects {
    margin: 1rem;
    padding: 1rem;
    background-color: #C9D1D3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    color: white;
}

.projects p {
    width: 90%;
    color: #000000;
    line-height: 2.5rem;
    font-size: 1.5rem;
    margin-left:5%;
}

.title {
    word-spacing: 3px;
    letter-spacing: 2px;
    text-decoration: underline;
    padding-top: 3rem;
    font-size: 3rem;
    color: #1B2A49;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

span {
    font-size: 1rem;
    color: #000000;
    text-align: left;
    /* padding:5%;
    margin:5%; */
}

.projects a {
    text-decoration: none;
    font-size:1.1rem;
    color: #204b9f;
}
/* .contactme a:hover,
.contactme a:visited,
.contactme a.active {
    color: white;
} */